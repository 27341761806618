<template>
  <v-layout
    v-if="route.path != '/'"
    class="relative !flex h-screen w-screen flex-col overflow-auto"
  >
    <WelcomeDoritoBg v-if="runtimeConfig.public.ENABLE_DORITO === 'true'" />
    <WelcomeDorito v-if="runtimeConfig.public.ENABLE_DORITO === 'true'" />
    <LayoutHeader />
    <div class="flex">
      <SnackbarDefault />
    </div>
    <div class="flex-1 overflow-y-auto">
      <slot />
      <div v-if="tutorialTagVisible && isMobile" :style="`height: ${tutorialTagHeight}px`" />
    </div>
    <AppFooter />
  </v-layout>
  <v-layout v-else>
    <slot />
  </v-layout>
</template>

<script setup lang="ts">
import { useRoute, useRuntimeConfig } from 'nuxt/app'
import { storeToRefs } from 'pinia'
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
import LayoutHeader from './LayoutHeader.vue'
import WelcomeDorito from './Comp/WelcomeDorito.vue'
import WelcomeDoritoBg from './Comp/WelcomeDoritoBg.vue'
import { useLayoutStore } from '~/store/layoutStore'

const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const layoutStore = useLayoutStore()
const { tutorialTagVisible, tutorialTagHeight } = storeToRefs(layoutStore)

const display = useDisplay()
const isMobile = computed(() => display.mobile.value)
</script>
