<template>
  <div
    ref="tutorialTagElement"
    class="fixed bottom-0 right-1/2 flex w-full translate-x-1/2 flex-col items-center gap-3 px-[16px] max-md:pb-3 md:right-16 md:ml-auto md:w-max md:translate-x-0 md:px-0 md:pb-5"
  >
    <TutorialFooter v-if="tutorialTagVisible" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import TutorialFooter from '~/layouts/Comp/TutorialFooter.vue'
import { useLayoutStore } from '~/store/layoutStore'

const layoutStore = useLayoutStore()
const { setTutorialTagHeight } = useLayoutStore()
const { tutorialTagVisible, tutorialTagOpened } = storeToRefs(layoutStore)

const tutorialTagElement = ref()

const syncTutorialTagHeight = () => {
  const tutorialHeight = tutorialTagElement.value?.clientHeight
  if (tutorialHeight) {
    setTutorialTagHeight(tutorialHeight)
  }
}

watch([tutorialTagVisible, tutorialTagOpened], () => {
  setTutorialTagHeight(0)
})

onMounted(() => {
  syncTutorialTagHeight()
  window.addEventListener('resize', syncTutorialTagHeight)
})
onUnmounted(() => window.removeEventListener('resize', syncTutorialTagHeight))
</script>
